<template>
<page v-bind="{ loading }" icon="calendar-alt" :title="`${Naming.Job} Schedule`">

  <template #tools>
    <route exact
      name="job-calendar"
      class="button is-rounded is-cool-blue-dark mr-1">
      <icon icon="calendar-alt"/>
      <span>{{ Naming.Calendar }}</span>
    </route>
    <new-job-dropdown />
  </template>

  <div class="">
    <div v-for="(jobs, date) in jobSchedule" :key="date">

      <h3 class="title is-4 mt-2">{{ date | date('L') }} <span class="has-text-weight-light">({{ jobs.length }})</span></h3>

      <table class="table is-fullwidth is-striped has-shadow is-rounded">
        <thead>
          <tr>
            <th>{{ Naming.Site }}</th>
            <th>{{ Naming.Job }}</th>
            <th width="20%">{{ Naming.Technician }}</th>
            <th width="20%">Dates</th>
            <th width="15%">{{ Naming.Inspections }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="pointer" @click="goToJob(job)" v-for="job in jobs" :key="job.id">
            <td class="is-narrow">
              <avatar :size="32" :path="job.site.logo_url" :name="job.site.name"/>
            </td>
            <td>
              <strong>{{ '#' + job.number +' '+ job.site.name }}</strong>
              <br>
              <span v-if="job.site.address">at {{ job.site.address.city }}</span>
              <span v-else>at {{ job.technician.full_name }}</span>
            </td>
            <td>
              {{ job.technician.full_name }}
            </td>
            <td>
              <span>
                {{ job.start_date | date('L') }}
                <template v-if="job.end_date">– {{ job.end_date | date('L') }}</template>
              </span>
            </td>
            <td>
              <span>
                <span class="is-inline-flex align-center mr">
                  <span class="has-tooltip-right has-text-grey" :data-tooltip="`${Naming.Inspections} completed`">
                    <icon icon="clipboard-list"/>
                  </span>
                  <span class="has-text-weight-bold">{{ job.inspections.length || '–' }}</span>
                </span>
                <span class="is-inline-flex align-center mr">
                  <span class="has-tooltip-right has-text-danger" :data-tooltip="`${Naming.Inspections} failed`">
                    <icon icon="times-circle" type="far"/>
                  </span>
                  <span class="has-text-weight-bold">{{ failedInspections(job.inspections) || '–' }}</span>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="box has-text-centered" v-if="!hasJobs">
      <p class="title is-5 has-text-grey has-text-weight-light">No {{ Naming.Jobs.toLowerCase() }} to display</p>
    </div>
  </div>
</page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  beforeCreate() {
    this.$store.dispatch('job/loadGroupedSchedule').then(() => {
      this.loading = false
    })
  },

  methods: {
    goToJob(job) {
      this.$router.push({
        name: 'job-handler',
        params: {
          site: job.site_id,
          job: job.id
        }
      })
    },
    failedInspections(inspections) {
      return inspections.filter(inspection => inspection.failed_at).length
    }
  },

  computed: {
    ...mapGetters('job', [
      'jobSchedule'
    ]),
    hasJobs() {
      return Object.keys(this.jobSchedule).length > 0
    }
  }

}
</script>

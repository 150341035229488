<template>
  <page v-bind="{ loading }"
        :loading="loading"
        icon="briefcase"
        :title="`${Naming.Job} Manager`"
        :loading-text="`Loading ${Naming.Job}…`"
  >

    <div slot="tools">
      <div class="buttons">
        <dropdown-wrapper position="bottom-end" v-if="inspections.data.length">
          <action-button
              id="dropdown-trigger"
              slot="trigger"
              class="is-white is-rounded"
              :working="downloading"
              left-icon="download">
            Download Report
          </action-button>
          <dropdown-item @click="downloadJobReport">{{ Naming.Job }} Report (PDF)</dropdown-item>
          <dropdown-item @click="downloadJobSummaryReport">{{ Naming.Job }} Summary Report (PDF)</dropdown-item>
          <dropdown-item @click="downloadJobWithInspectionsReports">{{ Naming.Job }} + {{ Naming.Inspections }} Reports (ZIP)</dropdown-item>
          <dropdown-item @click="downloadJobWithFailedInspectionsReports">{{ Naming.Job }} + Failed {{ Naming.Inspections }} Reports (ZIP)
          </dropdown-item>
        </dropdown-wrapper>

        <action-button
            v-if="canSignAndClose"
            :disabled="!hasInspectionsOrNoInProgressInspections"
            @click="approve"
            left-icon="check"
            class="button is-primary is-rounded has-tooltip-bottom"
            :data-tooltip="hasInspectionsOrNoInProgressInspectionsToolTip">
          Sign {{ Naming.Job }}
        </action-button>
        <action-button
            v-if="isAdmin && $root.hasAbility('manage-jobs', 'Billow\\Models\\Job')"
            @click="edit"
            left-icon="wrench"
            class="button is-primary is-rounded">
          Tools
        </action-button>
      </div>
    </div>

    <div class="grid gap-1">
      <div class="box p-2">
        <article class="is-flex align-center justify-between">
          <div class="is-flex">
            <avatar
                :size="48"
                class="mr-1"
                :path="job.site.logo_url"
                :name="job.site.name"/>
            <div class="is-flex flex-column">
              <span class="is-size-5 has-text-weight-light">
                #{{ job.number }} – <span class="has-text-weight-medium">{{ job.type.description }}</span>
              </span>
              <span>
                <span class="has-text-grey">For {{ Naming.Client.toLowerCase() }} </span>
                <link-client-manager v-if="isAdmin" :client="job.site.client"/>
                <span v-else>{{ job.site.client.legal_name }}</span>
                <span class="has-text-grey">, at </span>
                <link-site-manager :site="job.site"/>
                <span class="has-text-grey">, from </span>
                {{ job.start_date | date('L') }}
                <template v-if="job.end_date">
                  <span class="has-text-grey">until</span>
                  {{ job.end_date | date('L') }}
                </template>
              </span>
            </div>
          </div>
          <div class="tags">
            <arbitrary-status
                :status-class="['has-text-weight-medium', job.zones.length ? 'info' : 'link']"
                :status="`${job.zones.length} ${$options.filters.plural(Naming.Zone, job.zones.length)}`"/>
            <arbitrary-status v-if="isPendingSignature" class="ml-1" :status="`Waiting on ${lastAssignee}`"/>
            <current-status class="ml-1 mr-2" :status="job.current_status" :due="job.end_date"/>
          </div>
        </article>
      </div>

      <!-- Meta & Stats -->
      <div class="grid has-3-columns gap-1">
        <!-- Completion -->
        <article class="box stat minimal flipped">
          <div class="headline">Completion</div>
          <div class="value">
            <span v-if="job.completed_at">
              Completed {{ job.completed_at | date('L LTS') }}
            </span>
            <span v-else class="has-text-grey">
              Not yet completed
            </span>
          </div>
        </article>

        <!-- Opened By -->
        <article class="box stat minimal flipped">
          <div class="headline">Opened By</div>
          <span class="value is-flex-align-center">
            <avatar
                :size="24"
                class="mr"
                :path="job.user.profile.picture_url"
                :name="job.user.full_name"/>
            <route name="user-overview" :params="{ user: job.user.id }">{{ job.user.full_name }}</route>
          </span>
        </article>

        <!-- Technician -->
        <article class="box stat minimal flipped">
          <div class="headline">Assigned {{ Naming.Technician }}</div>
          <span class="value is-flex-align-center">
            <avatar
                :size="24"
                class="mr"
                :path="job.technician.profile.picture_url"
                :name="job.technician.full_name"/>
            <route name="user-overview" :params="{ user: job.technician.id }">{{ job.technician.full_name }}</route>
          </span>
        </article>
      </div>

      <div class="box">
        <h2 class="is-size-4 has-text-primary">{{ Naming.Job }} Times</h2>

        <p v-if="!job.start_date || job.start_date_is_future">This {{ Naming.Job.toLowerCase() }} has not yet started.</p>

        <div class="ptb-1" v-if="job.start_date && !job.completed_at && !job.start_date_is_future">
          This {{ Naming.Job.toLowerCase() }} was started on {{ job.start_date | date('L') }} and has been running for a total of
          <strong>{{ totalRuntime(job.total_minutes) }}.</strong>
          <span v-if="job.pauses.length">The {{ Naming.Job.toLowerCase() }} has been paused for a total of {{
              totalRuntime(totalPauseTime)
            }}</span>
        </div>

        <div class="pt-1" v-if="job.start_date && job.completed_at">
          This {{ Naming.Job.toLowerCase() }} was started on {{ job.start_date | date('L LTS') }} and completed {{
            job.completed_at | date('L LTS')
          }}.
          The {{ Naming.Job.toLowerCase() }} ran for a total of <strong>{{ totalRuntime(job.total_minutes) }}.</strong>
          <span v-if="job.pauses.length">The {{ Naming.Job.toLowerCase() }} was paused for a total of {{ totalRuntime(totalPauseTime) }}.</span>
        </div>

        <div class="columns" v-if="!job.start_date_is_future">
          <div class="column is-5">
            <p class="is-flex justify-between">Total Runtime <strong>{{ totalRuntime(job.total_minutes) }}</strong></p>
            <p class="is-flex justify-between">Total Pause Time <strong>{{ totalRuntime(totalPauseTime) }}</strong></p>
            <p class="is-flex justify-between">Adjusted Runtime (less pauses) <strong>{{
                totalRuntime(job.total_minutes - totalPauseTime)
              }}</strong></p>
          </div>
        </div>

        <h2 class="is-size-5 has-text-primary" v-if="!job.start_date_is_future">{{ Naming.Job }} Pauses ({{
            job.pauses.length
          }})</h2>
        <table class="table is-fullwidth" v-if="!job.start_date_is_future">
          <thead>
          <tr>
            <th>Paused At</th>
            <th>Paused Ended At</th>
            <th>Duration</th>
          </tr>
          </thead>
          <thead>
          <tr v-for="pause in job.pauses" :key="pause.id">
            <td>{{ pause.start | date('L LTS') }}</td>
            <td>{{ pause.end || 'The pause has not yet ended.' }}</td>
            <td>{{ pause.duration }}</td>
          </tr>
          </thead>
        </table>
        <p v-if="!job.pauses.length && !job.start_date_is_future" class="has-text-centered">This {{ Naming.Job.toLowerCase() }} has not been
          paused.</p>
      </div>

      <!-- Job Closing Comments -->
      <div class="box" v-if="hasJobClosingAnswers">
        <div class="columns">
          <div class="column">
            <h2 class="is-size-5 has-text-primary">{{ Naming.Job }} Closing Comments</h2>
          </div>
        </div>
        <div class="columns">
          <div class="column table-container">
            <table class="table is-striped is-hoverable is-fullwidth">
              <tbody>
              <tr class="border-1 border-white" v-for="(answer, key) in job.answers" :key="key">
                <td class="bg-gray-100 w-1/2">
                  <p class="text-sm font-bold p-1">{{ key }} :</p>
                </td>
                <td class="bg-gray-50 w-1/2">
                  <p class="text-sm pl-1">{{ answer }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="columns">
          <div class="column">
            <inspections
                :job-id="$route.params.job"
                :site-id="$route.params.site"
                @nav="goToPage"
                @searchInspections="searchInspections"
                @clearedSearch="refreshManager"/>
          </div>
        </div>
      </div>

      <div class="box" v-if="documents.length">
        <div class="columns">
          <div class="column">
            <h2 class="is-size-5 has-text-primary mb-2">Uploaded Documents ({{ documents.length }})</h2>
            <div class="is-flex align-items-center pl-1 pb-1" v-for="document in documents" :key="document.id">
              <button @click.prevent="downloadDocument(document)" class="button"><i class="fa fa-download"></i></button>
              <div class="pl-1">{{ document.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import download from 'downloadjs'
import {job as backend} from '@/api'
import {mapGetters} from 'vuex'
import {jobClosingComments, jobSummaryReport, jobTools, signJob, viewEvidence} from '@/modals'
import Inspections from './partials/Inspections'
import common from '@/api/common'

export default {

  components: {
    Inspections,
  },

  data: () => ({
    loading: true,
    downloading: false,
    downloadingInspection: false,
    job_approval_requests: null
  }),

  created() {
    this.loadManager()
  },

  methods: {
    loadManager() {
      Promise.all([
        this.$store.dispatch('job/loadJobManager', {
          path: 'api' + this.$route.fullPath,
        }).then(({inspections, documents, job_approval_requests}) => {
          this.$store.commit('inspection/setInspections', inspections)
          this.$store.commit('documents/setDocuments', documents)
          this.job_approval_requests = job_approval_requests
        }),
      ]).then(() => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    searchInspections(filters) {
      this.$router.push({
        name: 'job-handler',
        params: {
          job: this.job.id,
        },
        query: filters,
      })
    },
    refreshManager() {
      this.$router.push({
        name: 'job-handler',
        params: {
          job: this.job.id,
        },
      })
    },
    edit() {
      this.$store.commit('job/setJob', this.job)
      jobTools().then(action => {
        if (action.reload) {
          this.loadManager()
        }

        if (action.closing_comments && this.hasInspectionsOrNoInProgressInspections) {
          jobClosingComments().then(reload => {
            if (reload) {
              this.loadManager()
            }
          })
        }
        if (action.closing_comments && !this.hasInspectionsOrNoInProgressInspections) {
          this.$toast.error('Job has no inspections or in progress inspections.')
        }
      })
    },
    approve() {
      this.$store.commit('job/setJob', this.job)
      signJob().then(
          reload => {
            if (reload) {
              this.loadManager()
            }
          }
      )
    },
    downloadJobSummaryReport() {
      this.$store.commit('job/setJob', this.job)
      jobSummaryReport().then(
          // send the request
      )
    },
    downloadJobReport() {
      this.downloading = true
      this.$toast.success(this.Convert(`Downloading job report`))
      if (this.inspections.total > 20) {
        this.$toast.success(this.Convert("We'll email you a download link once your job report is ready. This could take a few minutes."))
        backend.downloadReport(this.job.id, ({data}) => {
          this.downloading = false
        }, error => {
          this.$whoops()
          this.downloading = false
        })
        return
      }
      backend.downloadReport(this.job.id, ({data}) => {
        this.downloading = false
        download(data, `${this.Convert('job').toLowerCase()}-report-#${this.job.number}-${this.job.site.name}.pdf`, 'application/pdf')
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    },
    downloadJobWithInspectionsReports() {
      this.downloading = true

      if (this.inspections.total >= 5) {
        this.$toast.success(`We'll email you a download link once we've built your reports. This could take a few minutes.`)
        backend.downloadJobWithInspectionsReports(this.job.id, ({data}) => {
          this.downloading = false
        }, error => {
          this.$whoops()
          this.downloading = false
        })
        return
      }

      this.$toast.success(this.Convert("Downloading job with inspections reports. Please wait..."))
      backend.downloadJobWithInspectionsReports(this.job.id, ({data}) => {
        this.downloading = false
        download(data, `#${this.job.number}-${this.job.site.name}-job-with-inspections-reports.zip`, 'application/zip')
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    },
    downloadJobWithFailedInspectionsReports() {
      this.downloading = true

      if (this.inspections.total >= 5) {
        this.$toast.success(
            `We'll email you a download link once we've built your reports. This could take a few minutes.`)
        backend.downloadJobWithFailedInspectionsReports(this.job.id, ({data}) => {
          this.downloading = false
        }, error => {
          this.$whoops()
          this.downloading = false
        })
        return
      }

      this.$toast.success(this.Convert(`Downloading job with failed inspections reports. Please wait...`))
      backend.downloadJobWithFailedInspectionsReports(this.job.id, ({data}) => {
        this.downloading = false
        download(data, `#${this.job.number}-${this.job.site.name}-${this.Convert('job').toLowerCase()}-with-${this.Convert('inspections').toLowerCase()}-reports.zip`, 'application/zip')
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    },
    viewInspectionEvidence(inspection) {
      this.$store.commit('inspection/setInspection', inspection)
      viewEvidence(inspection)
    },
    failedOnInspection(inspection) {
      return inspection.answers.filter(answer => answer.answer === 0).length
    },
    passedOnInspection(inspection) {
      return inspection.answers.filter(answer => answer.answer !== 0).length
    },
    percentage(inspection) {
      return Number(((this.passedOnInspection(inspection) / inspection.answers.length) * 100).toFixed(2))
    },
    downloadDocument(document) {
      this.$toast.info('Download Started')
      common.downloadFile(`/api/documents/${document.id}/download`, ({data}) => {
        download(data, `${document.title}`, document.mime)
      })
    },
    totalRuntime(minutes) {
      if (minutes < 60) {
        return minutes + ' minutes'
      }
      if (minutes < 1440) {
        return (minutes / 60).toFixed(0) + ' hours'
      }

      if (minutes > 1440) {
        let days = (minutes / (60 * 24)).toFixed(0)
        if (days === '1') {
          return days + ' day'
        }
        return days + ' days'
      }
    },
  },

  computed: {
    ...mapGetters('job', [
      'job',
    ]),
    ...mapGetters('inspection', [
      'inspections',
    ]),
    ...mapGetters('documents', [
      'documents',
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    totalPauseTime() {
      return this.job.pauses.reduce((carry, pause) => carry + pause.total_minutes, 0)
    },
    isAssignee() {
      if (this.job_approval_requests) {

        return this.job_approval_requests?.some(job_approval_request => job_approval_request?.assignee?.id === this.user?.id)
      }
      return false
    },
    isPendingSignature() {
      return this.job?.current_status?.value === 'pending-signature'
    },
    lastAssignee() {
      if (this.job_approval_requests) {
        return this.job_approval_requests[0]?.assignee?.fullName ?? "Unknown"
      }
      return "Not Found"
    },
    hasInspections() {
      return this.inspections?.data?.length > 0
    },
    hasNoInProgressInspections() {
      return !this.inspections?.data?.some(inspection => inspection.completed_at == null)
    },
    hasInspectionsOrNoInProgressInspectionsToolTip() {
      if (this.hasInspectionsOrNoInProgressInspections === false) {
        return `${this.Naming.Job} has no ${this.Naming.Inspections} or has in progress ${this.Naming.Inspections}.\n${this.Naming.Inspections} need to be complete before signing is allowed`
      }
      return null
    },
    hasInspectionsOrNoInProgressInspections() {
      return this.hasInspections && this.hasNoInProgressInspections
    },
    canSignAndClose() {
      return this.isAdmin && this.$root.hasAbility('close-jobs', 'Billow\\Models\\Job') && this.isAssignee && !this.job.completed_at
    },
    hasJobClosingAnswers() {
      return this.job.answers
    },
  },

  watch: {
    '$route': 'loadManager',
  },
}
</script>
